export function getJwtPayload<T>(token: string): T | null {
  try {
    const base64Payload = token.split(".")[1];
    const base64 = base64Payload.replace("-", "+").replace("_", "/");

    return JSON.parse(window.atob(base64)) as T;
  } catch (e) {
    return null;
  }
}
