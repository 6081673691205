import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";

import { Input, PasswordInput } from "@ui-kit/InputFields";
import Dialog from "@ui-kit/Dialog";
import { getJwtPayload } from "@helpers/jwt";
import { createCss } from "./styles";
import { ReactGAEventWait } from "@helpers/ga";
import Button from "@ui-kit/Button";
import { required, minLength } from "@validators";
import { Models, USER_API } from "@services/api";
import { useUserStore } from "@store/UserStore";
import { useAppStore } from "@store/AppStore";
import Typography from "@ui-kit/Typography";

type FormFields = {
  email: string;
  password: string;
};

const EXPIRED_ERROR = "Link expired";

const ResetPasswordForm: React.FC = () => {
  const { handleSubmit, control, errors, formState, reset } =
    useForm<FormFields>();
  const [{ realLocation }] = useAppStore();
  const location = useLocation();
  const history = useHistory();
  const [{ user }, { resetPassword }] = useUserStore();
  const [serverError, setServerError] = useState("");
  const [fetching, setFetching] = useState(false);

  const css = createCss();

  useEffect(() => {
    const validateToken = async () => {
      try {
        setFetching(true);
        const { data } = await USER_API.usersPasswordResetValidate({
          usersPasswordResetValidate: {
            token: params.token as string,
          },
        });

        setServerError(!data ? EXPIRED_ERROR : "");
      } finally {
        setFetching(false);
      }
    };

    ReactGAEventWait({ category: "PasswordReset", action: "LinkOpened" });

    const params = qs.parse(location.search);
    const tokenPayload = getJwtPayload<{ email: string }>(
      params.token as string,
    );

    if (tokenPayload) {
      reset({
        email: tokenPayload.email,
      });
    }

    void validateToken();
  }, []);

  useEffect(() => {
    if (user) {
      closeModal();
    }
  }, [user]);

  function closeModal() {
    ReactGAEventWait({ category: "PasswordReset", action: "LinkClosed" });

    history.replace(
      realLocation.current.pathname + realLocation.current.search,
    );
  }

  const submit = handleSubmit(async ({ password }) => {
    const params = qs.parse(location.search);

    try {
      await resetPassword({
        password,
        token: params.token as string,
      });
    } catch (error) {
      if (error.response?.status === 400) {
        setServerError(EXPIRED_ERROR);
      } else {
        throw error;
      }
    }
  });

  return (
    <Dialog
      open
      onClose={closeModal}
      title="Reset password"
      data-test="reset-password-dialog"
      loading={fetching}
      tier={Models.SubscriptionTier.Ivy}
    >
      {!serverError ? (
        <form css={css.container} data-test="reset-password-form">
          <div>
            <Controller
              render={(controllerProps) => (
                <Input {...controllerProps} label="Email" disabled />
              )}
              name="email"
              control={control}
              defaultValue=""
            />

            <Controller
              as={
                <PasswordInput
                  error={errors.password?.message}
                  data-test="new-password-field"
                  autoComplete="new-password"
                  helperText="Password must be at least 6 characters"
                />
              }
              rules={{
                ...required(),
                ...minLength(6),
              }}
              name="password"
              control={control}
              defaultValue=""
            />
          </div>
          <div css={css.footer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={submit}
              data-test="reset-btn"
              loading={formState.isSubmitting}
            >
              Reset password
            </Button>
          </div>
        </form>
      ) : (
        <div css={css.container}>
          <Typography color="error" align="center" bolded>
            {serverError}
          </Typography>
          <div css={css.footer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              link={{
                pathname: "/forgot-password",
              }}
            >
              Resend email
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ResetPasswordForm;
